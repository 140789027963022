import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dmContext from './context';
import { useRouter } from 'next/router';
import { getChatRooms, getJamUserByRoomId } from '@/services/chat/p2p';
import {
  getAvailableRoomsByTwitterId,
  getChatGroups,
  getRoomMembersByRoomId,
  pinCommunity,
} from '@/services/chat/group';
import { WalletContext } from '@/contexts/wallet-context';
import {
  useSocketEvent,
  useSocketProvider,
} from '@/providers/SocketProvider/hooks';
import { v4 } from 'uuid';
import {
  AIProfileChecking,
  AddMemberFromGroupRealTime,
  BlockChatUser,
  CallMemberChangedType,
  ChatTab,
  ChatType,
  DMMessage,
  DMRealTimeMessage,
  DMRoom,
  DMScreen,
  GroupCallingEnded,
  MemberInCall,
  PinUpdateSocket,
  RemoveMemberFromGroupRealTime,
  ReplyMessageData,
  RoomLanguageSettings,
  UnblockChatUser,
  UpdateRealTimeRoomCalling,
} from '@/modules/AlphaPWA/DirectMessage/types';
import {
  ChatGroupMember,
  ChatGroupResponse,
  ChatMessageResponse,
  ChatPeerToPeerResponse,
  ChatTribeResponse,
  ChatTypeResponse,
  LanguageSupportedEnum,
} from '@/interfaces/api/chat';
import { ROUTE_PATH } from '@/constants/route-path';
import {
  DEFAULT_GET_ROOM_LIMIT,
  DEFAULT_LIMIT_GET_MESSAGE,
  ROOM_CHAT_EVENT_SCROLLING,
  ROOM_CHAT_ON_AVAILABLE_MESSAGE_LENGTH,
} from '@/modules/AlphaPWA/DirectMessage/constants';
import { Scrollbars } from 'react-custom-scrollbars';
import { AssetsContext } from '@/contexts/assets-context';
import {
  getAllRoomIds,
  getChatMessageDetail,
  getMessageByTime,
  getRoomById,
  setPinChatRoom,
  updateLastSeen,
} from '@/modules/AlphaPWA/DirectMessage/api';
import {
  formatMessageResponse,
  unifyChatGroup,
  unifyChatRoom,
  unifyChatTribe,
} from '@/services/chat/helpers';
import { difference, differenceBy, uniqBy } from 'lodash';
import { compareString } from '@/utils';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';

import { poolProfilePersistor, chatRoomPersistor } from '@/utils/persistor';
import VoiceRoomDrawer from '@/modules/AlphaPWA/DirectMessage/VoiceRoom/Drawer';
import { useAgora } from '@/providers/AgoraProvider/hooks';
import { getChatTribes, getChatGroup } from '@/services/chat/tribe';
import { closeModal, openModal } from '@/state/modal';
import MintMessageModal, {
  MINT_MESSAGE_MODAL_NAME,
} from '@/modules/AlphaPWA/DirectMessage/Chat/MintMessageModal';
import { useAppDispatch } from '@/state/hooks';
import {
  getPassTokenDetail,
  getTokenHolders,
  getTokenHolding,
} from '@/services/player-share';
import moment from 'moment';
import CPlayerShare from '@/contracts';
import {
  createLocalTime,
  getLocalLastSeenByRoomId,
  getLocalLastSeens,
  updateLocalLastSeens,
} from '@/modules/AlphaPWA/DirectMessage/helpers';
import { BigNumber } from 'ethers';
import { EMOJI_STICKERS } from '../Home/PostItem/Emoji';
import useApiInfinite from '@/hooks/useApiInfinite';
import { useDisclosure } from '@chakra-ui/react';

type Props = Pick<React.ComponentPropsWithoutRef<'div'>, 'children'>;

export default function DMProvider({ children }: Props) {
  const {
    pathname,
    query: { id: dmId, address },
  } = useRouter();
  const [roomId, setRoomId] = useState<string | undefined>(undefined);
  const id = roomId || dmId;

  const dispatch = useAppDispatch();
  const { emptySpeaker } = useAgora();
  const playerShareContract = new CPlayerShare();

  const { sendEmit, isConnected } = useSocketProvider();
  const newMessage = useSocketEvent<DMRealTimeMessage>(
    'SEND_MESSAGE',
    JSON.parse
  );
  const newRoom = useSocketEvent<ChatPeerToPeerResponse>(
    'NEW_CHAT_ROOM',
    JSON.parse
  );
  const removeRoomId = useSocketEvent<string>('REMOVE_CHAT_ROOM');

  const newGroup = useSocketEvent<ChatGroupResponse>(
    'NEW_GROUP_CHAT',
    JSON.parse
  );

  const deleteMessageGroup = useSocketEvent<ChatMessageResponse>(
    'GROUP_DELETE_MESSAGE',
    JSON.parse
  );

  const addMemberToGroup = useSocketEvent<AddMemberFromGroupRealTime>(
    'ADD_MEMBER_TO_GROUP_CHAT',
    JSON.parse
  );

  const updateLixiMessage = useSocketEvent<ChatMessageResponse>(
    'CLAIM_LIXI_MESSAGE_UPDATE',
    JSON.parse
  );

  const removeMemberFromGroup = useSocketEvent<RemoveMemberFromGroupRealTime>(
    'REMOVE_MEMBER_FROM_GROUP_CHAT',
    JSON.parse
  );

  const newGroupMessage = useSocketEvent<ChatMessageResponse>(
    'GROUP_MESSAGE_SENT',
    JSON.parse
  );

  const updateGroupMessageTip = useSocketEvent<ChatMessageResponse>(
    'OPEN_TIP_V2_EVENT',
    JSON.parse
  );

  const groupCallUpdated = useSocketEvent<UpdateRealTimeRoomCalling>(
    'GROUP_CALL',
    JSON.parse,
    true
  );

  const groupCallingEnded = useSocketEvent<GroupCallingEnded>(
    'END_GROUP_CALL',
    JSON.parse,
    true
  );

  const blockChatMember = useSocketEvent<BlockChatUser>(
    'BLOCK_CHAT_MEMBER',
    JSON.parse
  );

  const unblockChatMember = useSocketEvent<UnblockChatUser>(
    'UNBLOCK_CHAT_MEMBER',
    JSON.parse
  );

  const memberJoinCall = useSocketEvent<CallMemberChangedType>(
    'UPDATE_CALL_MEMBER_JOIN',
    JSON.parse
  );

  const memberLeaveCall = useSocketEvent<CallMemberChangedType>(
    'UPDATE_CALL_MEMBER_LEAVE',
    JSON.parse
  );

  const pinUpdateSocket = useSocketEvent<PinUpdateSocket>(
    'SET_PIN_GROUP_ROOM',
    JSON.parse
  );

  const { addressL2 } = useContext(WalletContext);
  const [screen, setScreen] = useState<DMScreen>('');
  const [tab, setTab] = useState<ChatTab>('TRIBES');
  const [rooms, setRooms] = useState<DMRoom[]>([]);
  const [roomMessages, setRoomMessages] = useState<Record<string, DMMessage[]>>(
    {}
  );
  const [aroundMessages, setAroundMessages] = useState<DMMessage[]>([]);

  const [voiceRoom, setVoiceRoom] = useState('');
  const [joinedVoiceRoom, setJoinedVoiceRoom] = useState(false);
  const [isRoomActiveIsCommunity, setIsRoomActiveIsCommunity] = useState(false);
  const [isRoomActiveIsOwner, setIsRoomActiveIsOwner] = useState(false);

  const [activeRoomMembers, setActiveRoomMembers] = useState<ChatGroupMember[]>(
    []
  );
  const [dataReply, setDataReply] = useState<any>(null);

  const [showAppSelect, setShowAppSelect] = useState(false);
  const [openSticker, setOpenSticker] = useState(false);

  const [dataReplyPreview, setDataReplyPreview] =
    useState<ReplyMessageData | null>(null);

  const { playerPoolProfile: yourPoolProfile } = useContext(AssetsContext);
  const [memberInCalls, setMemberInCalls] = useState<
    Record<string, MemberInCall>
  >({});

  const [AIProfiles, setAIProfiles] = useState<
    Record<string, AIProfileChecking>
  >({});

  const [roomChatAvailableOfUsers, setRoomChatAvailableOfUsers] = useState<
    Record<string, DMRoom[]>
  >({});

  const [roomOwnerTwitterId, setRoomOwnerTwitterId] = useState('');
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [forwardMessage, setForwardMessage] = useState<DMMessage>();

  const {
    isOpen: isForwardMessageTargets,
    onOpen: onOpenForwardMessageTargets,
    onClose: onCloseForwardMessageTargets,
  } = useDisclosure({
    id: 'forward-message-targets-modal',
  });

  const activeRoom = useMemo(() => {
    if (id) {
      console.log('rooms______', rooms);
      console.log(
        'test active room',
        rooms.find(r => r.id === id)
      );
      return rooms.find(r => r.id === id);
    }
    return;
  }, [id, rooms]);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url && !url.includes('/dm?id=')) {
        setRoomId(undefined);
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    setIsRoomActiveIsOwner(compareString(activeRoom?.owner, addressL2));
    setIsRoomActiveIsCommunity(
      activeRoom?.isTribe || activeRoom?.type === 'CHAT_TRIBE'
    );
  }, [activeRoom, addressL2]);

  useEffect(() => {
    if (!isForwardMessageTargets) {
      setForwardMessage(undefined);
    }
  }, [isForwardMessageTargets]);

  useEffect(() => {
    if (activeRoom?.id) {
      ROOM_CHAT_EVENT_SCROLLING[activeRoom?.id] = false;
      setDataReply(null);
      setDataReplyPreview(null);
      setAroundMessages([]);
      onCloseForwardMessageTargets();
    }
  }, [activeRoom?.id]);

  useEffect(() => {
    if (addressL2 && AIProfiles && screen) {
      const checkYourAIPassToken = async (token: string) => {
        const aiProfile = AIProfiles[token];
        if (aiProfile && aiProfile.profile && !aiProfile.holdingToken) {
          const passToken = await getPassTokenDetail({
            address: aiProfile.profile.address,
          });
          if (passToken && passToken?.address) {
            const balanceToken = await playerShareContract.getTokenBalance(
              passToken?.address
            );

            if (
              balanceToken &&
              Number(balanceToken) &&
              Number(balanceToken) > 0
            ) {
              setAIProfiles(prev => {
                return {
                  ...prev,
                  [token]: {
                    ...(prev[token] || {}),
                    holdingToken: true,
                  },
                };
              });
            }
          }
        }
      };

      Object.keys(AIProfiles).forEach(token => {
        checkYourAIPassToken(token);
      });
    }
  }, [addressL2, AIProfiles, screen]);

  const roomsRef = useRef(rooms);
  const roomMessagesRef = useRef(roomMessages);
  const addressL2Ref = useRef(addressL2);
  const activeRoomRef = useRef(activeRoom);
  const reconnectTimeoutHandlerRef = useRef<ReturnType<typeof setTimeout>>();
  const isInitialRoomsRef = useRef(true);
  const memberInCallsRef = useRef(memberInCalls);
  const timeoutIdRef = useRef<null | NodeJS.Timeout>(null);
  const roomChatAvailableOfUsersRef = useRef(roomChatAvailableOfUsers);

  const isBlockedFromGroupChat = useMemo((): boolean => {
    const yourInfo = activeRoomMembers?.find(mem => {
      return mem.twitterId === yourPoolProfile?.twitterId;
    });

    return !!yourInfo?.isChatBlocked;
  }, [activeRoomMembers, yourPoolProfile, blockChatMember, unblockChatMember]);

  const openBottomBar = useMemo(
    () => openSticker || showAppSelect,
    [openSticker, showAppSelect]
  );

  useEffect(() => {
    if (rooms.length) {
      const lastSeens = getLocalLastSeens();
      rooms.forEach(room => {
        if (lastSeens[room.id]) {
          if (moment(lastSeens[room.id]).isAfter(room.lastSeen)) {
            updateLastSeen(room.type, {
              roomId: room.id,
            });
            updateRoomLastSeen(room.id, lastSeens[room.id]);
          } else if (room.lastSeen) {
            updateLocalLastSeens(room.id, room.lastSeen);
          }
        }
      });
    }
  }, [rooms.length]);

  useEffect(() => {
    roomChatAvailableOfUsersRef.current = roomChatAvailableOfUsers;
  }, [roomChatAvailableOfUsers]);

  useEffect(() => {
    if (groupCallUpdated) {
      setRooms(prev => {
        const foundRoomIndex = prev.findIndex(
          item => item.id === groupCallUpdated.roomId
        );
        if (foundRoomIndex >= 0) {
          prev[foundRoomIndex] = {
            ...prev[foundRoomIndex],
            isCalling: true,
          };
        }
        return [...prev];
      });
    }
  }, [groupCallUpdated]);

  useEffect(() => {
    if (groupCallingEnded) {
      setRooms(prev => {
        const foundRoomIndex = prev.findIndex(
          item => item.id === groupCallingEnded.roomId
        );
        if (foundRoomIndex >= 0) {
          prev[foundRoomIndex] = {
            ...prev[foundRoomIndex],
            isCalling: false,
          };
        }
        return [...prev];
      });

      setMemberInCalls(prev => {
        prev[groupCallingEnded.roomId] = {
          members: [],
          total: 0,
        };
        return { ...prev };
      });
    }
  }, [groupCallingEnded]);

  useEffect(() => {
    if (emptySpeaker) {
      setRooms(prev => {
        const foundRoomIndex = prev.findIndex(
          item => item.id === emptySpeaker.roomId
        );
        if (foundRoomIndex >= 0) {
          prev[foundRoomIndex] = {
            ...prev[foundRoomIndex],
            isCalling: false,
          };
        }
        return [...prev];
      });

      setMemberInCalls(prev => {
        prev[emptySpeaker.roomId] = {
          members: [],
          total: 0,
        };
        return { ...prev };
      });
    }
  }, [emptySpeaker]);

  useEffect(() => {
    if (memberJoinCall) {
      setMemberInCalls(prev => {
        const updateData = {
          members: [],
          total: 0,
          ...(prev[memberJoinCall.roomId] || {}),
        };

        // checj if member is already in call
        const foundMember = updateData.members.findIndex(member => {
          return compareString(member, memberJoinCall.address);
        });
        if (foundMember === -1) {
          updateData.members.push(memberJoinCall.address);
          updateData.total++;
        }

        if (updateData.total < updateData.members.length) {
          updateData.total = updateData.members.length;
        }
        prev[memberJoinCall.roomId] = updateData;
        return { ...prev };
      });
    }
  }, [memberJoinCall]);

  useEffect(() => {
    if (memberLeaveCall) {
      setMemberInCalls(prev => {
        const updateData = {
          members: [],
          total: 0,
          ...(prev[memberLeaveCall.roomId] || {}),
        };

        if (updateData.members.length >= 1) {
          const foundMember = updateData.members.findIndex(member => {
            return compareString(member, memberLeaveCall.address);
          });

          if (foundMember >= 0) {
            updateData.members.splice(foundMember, 1);
            updateData.total--;
          }

          // updateData.members = updateData.members.filter(
          //   add => !compareString(add, memberLeaveCall.address)
          // );
          // updateData.total--;
          if (updateData.total < updateData.members.length) {
            updateData.total = updateData.members.length;
          }
        }
        prev[memberLeaveCall.roomId] = updateData;
        return { ...prev };
      });
    }
  }, [memberLeaveCall]);

  useEffect(() => {
    sendEmit('SUBSCRIBE_ADDRESS', addressL2);
  }, [addressL2]);

  useEffect(() => {
    addressL2Ref.current = addressL2;
  }, [addressL2]);

  useEffect(() => {
    roomsRef.current = rooms;
  }, [rooms]);

  useEffect(() => {
    roomMessagesRef.current = roomMessages;
  }, [roomMessages]);

  useEffect(() => {
    activeRoomRef.current = activeRoom;
  }, [activeRoom]);

  useEffect(() => {
    memberInCallsRef.current = memberInCalls;
  }, [memberInCalls]);

  const fetchRooms = (
    page = 1,
    limit = DEFAULT_GET_ROOM_LIMIT,
    force = false
  ) => {
    getChatRooms({
      page,
      limit,
    })
      .then(({ total, rooms: rs }) => {
        if (page < Math.ceil(total / limit) && rs.length === limit) {
          setTimeout(() => {
            fetchRooms(page + 1, DEFAULT_GET_ROOM_LIMIT, force);
          }, 10);
        }
        rs.forEach(r => {
          chatRoomPersistor.upsertItem(r.id, r);
        });

        appendNewRooms(rs, force);
      })
      .catch(() => {
        setTimeout(() => {
          fetchRooms(page, DEFAULT_GET_ROOM_LIMIT, force);
        }, 10);
      });
  };

  const fetchGroups = (
    page = 1,
    limit = DEFAULT_GET_ROOM_LIMIT,
    force = false
  ) => {
    getChatGroups({
      page,
      limit,
    })
      .then(({ total, rooms: rs }) => {
        if (page < Math.ceil(total / limit) && rs.length === limit) {
          setTimeout(() => {
            fetchGroups(page + 1, DEFAULT_GET_ROOM_LIMIT, force);
          }, 10);
        }
        rs.forEach(r => {
          chatRoomPersistor.upsertItem(r.id, r);
        });
        appendNewRooms(rs, force);
      })
      .catch(() => {
        setTimeout(() => {
          fetchGroups(page, DEFAULT_GET_ROOM_LIMIT, force);
        }, 10);
      });
  };

  const fetchTribes = (
    page = 1,
    limit = DEFAULT_GET_ROOM_LIMIT,
    force = false
  ) => {
    getChatTribes({
      page,
      limit,
    })
      .then(({ total, rooms: rs }) => {
        if (page < Math.ceil(total / limit) && rs.length === limit) {
          setTimeout(() => {
            fetchTribes(page + 1, DEFAULT_GET_ROOM_LIMIT, force);
          }, 10);
        }
        rs.forEach(r => {
          chatRoomPersistor.upsertItem(r.id, r);
        });
        appendNewRooms(rs, force);
      })
      .catch(() => {
        setTimeout(() => {
          fetchTribes(page, DEFAULT_GET_ROOM_LIMIT, force);
        }, 10);
      });
  };

  useEffect(() => {
    const getAllRooms = () => {
      fetchRooms(1, DEFAULT_GET_ROOM_LIMIT, true);
      fetchGroups(1, DEFAULT_GET_ROOM_LIMIT, true);
      fetchTribes(1, DEFAULT_GET_ROOM_LIMIT, true);
    };
    fetchActiveRoomMessage(true);

    chatRoomPersistor
      .getAllItem()
      .then(storedItems => {
        if (storedItems.length) {
          const allStoredIds = storedItems.map(storedItem => storedItem.id);
          getAllRoomIds().then(allRoomIds => {
            const removeIds = difference(allStoredIds, allRoomIds);
            const addIds = difference(allRoomIds, allStoredIds);

            if (removeIds.length) {
              // remove room in cache and remove on ui
              removeIds.forEach(id => {
                removeRoomById(id);
                chatRoomPersistor.removeItem(id);
              });
            }

            if (addIds.length) {
              // add new room
              addIds.forEach(id => {
                getRoomById(id).then(newRoom => {
                  if (newRoom) {
                    appendNewRooms([newRoom]);
                    chatRoomPersistor.upsertItem(id, newRoom);
                  }
                });
              });
            }
          });

          const updateRooms = storedItems.map(item => ({
            ...item,
            unreadCount: 0,
            messageLoaded: false,
            // lastMessage: undefined,
          }));
          appendNewRooms(updateRooms);
        }
      })
      .finally(() => {
        getAllRooms();
      });

    // clear the field initial rooms
    setTimeout(() => {
      isInitialRoomsRef.current = false;
    }, 20000);
  }, []);

  useEffect(() => {
    if (reconnectTimeoutHandlerRef.current) {
      clearTimeout(reconnectTimeoutHandlerRef.current);
    }

    if (!isInitialRoomsRef.current) {
      if (isConnected) {
        reconnectTimeoutHandlerRef.current = setTimeout(() => {
          fetchActiveRoomMessage(true);
          fetchRooms(1, DEFAULT_GET_ROOM_LIMIT, true);
          fetchGroups(1, DEFAULT_GET_ROOM_LIMIT, true);
          fetchTribes(1, DEFAULT_GET_ROOM_LIMIT, true);
        }, 5000);
      }
    }

    return () => {
      if (reconnectTimeoutHandlerRef.current) {
        clearTimeout(reconnectTimeoutHandlerRef.current);
      }
    };
  }, [isConnected]);

  useEffect(() => {
    // refresh after every 2 minutes
    setInterval(
      () => {
        // just reload room list when user active
        if (window.userState === 'Active' && !roomsRef.current.length) {
          fetchRooms(1, DEFAULT_GET_ROOM_LIMIT);
          fetchGroups(1, DEFAULT_GET_ROOM_LIMIT);
          fetchTribes(1, DEFAULT_GET_ROOM_LIMIT, true);
        }
      },
      60 * 1000 * 2
    );
  }, []);

  const appendNewRooms = useCallback((newRooms: DMRoom[], force = false) => {
    const rs = newRooms.filter(r => r.owner);

    rs.forEach(room => {
      if (room.lastMessage) {
        addRealTimeMessage(room.lastMessage as DMMessage, false);
      }
    });

    setRooms(prev => {
      const addRs: DMRoom[] = [];
      rs.forEach(r => {
        let roomIndex = prev.findIndex(
          pr => pr.id === r.id && pr.type === r.type
        );
        if (roomIndex >= 0) {
          prev[roomIndex] = Object.assign(prev[roomIndex], r);
        } else {
          addRs.push(r);
        }
        if (force && prev[roomIndex]) {
          prev[roomIndex].messageLoaded = false;
        }
      });

      return [...prev, ...addRs];
    });
  }, []);

  useEffect(() => {
    return () => {
      if (activeRoom) {
        chatRoomPersistor.upsertItem(activeRoom.id, activeRoom);

        setRoomMessages(prev => {
          const messList = prev[activeRoom.id];
          if (
            messList &&
            messList.length > ROOM_CHAT_ON_AVAILABLE_MESSAGE_LENGTH
          ) {
            prev[activeRoom.id] = messList.slice(
              messList.length - ROOM_CHAT_ON_AVAILABLE_MESSAGE_LENGTH
            );
          }
          return { ...prev };
        });
        // cut off last active room to release memory
      }
    };
  }, [activeRoom?.id]);

  const removeRoomById = (removeId: string) => {
    setRooms(prev => {
      return prev.filter(p => p.id !== removeId);
    });
    setRoomMessages(prev => {
      if (prev[removeId]) {
        delete prev[removeId];
      }
      return { ...prev };
    });
  };

  useEffect(() => {
    if (removeRoomId) {
      removeRoomById(removeRoomId);
    }
  }, [removeRoomId]);

  useEffect(() => {
    if (updateLixiMessage) {
      const rTMessage = formatMessageResponse<DMMessage>(updateLixiMessage);
      setRoomMessages(prev => {
        if (prev[rTMessage.roomId]) {
          const messageIndex = prev[rTMessage.roomId].findIndex(
            m => m.id === rTMessage.id
          );
          if (messageIndex >= 0) {
            prev[rTMessage.roomId][messageIndex] = {
              ...prev[rTMessage.roomId][messageIndex],
              ...rTMessage,
            };
          }
        }
        return prev;
      });
    }
  }, [updateLixiMessage]);

  useEffect(() => {
    if (addMemberToGroup && addMemberToGroup.info) {
      setRooms(prev => {
        const updateGroup = prev.find(r => r.id === addMemberToGroup.roomId);
        if (updateGroup) {
          updateGroup.members = [...updateGroup.members, addMemberToGroup.info];
        }
        return [...prev];
      });
    }
  }, [addMemberToGroup]);

  useEffect(() => {
    if (removeMemberFromGroup && removeMemberFromGroup.info) {
      if (
        compareString(addressL2Ref.current, removeMemberFromGroup.info.address)
      ) {
        const roomRemove = roomsRef.current.find(
          r => r.id === removeMemberFromGroup.roomId
        );
        if (roomRemove) {
          setRooms(prev => {
            return prev.filter(p => p.id !== roomRemove.id);
          });
          setRoomMessages(prev => {
            if (prev[roomRemove.id]) {
              delete prev[roomRemove.id];
            }
            return { ...prev };
          });
        }
      } else {
        setRooms(prev => {
          const updateGroup = prev.find(
            r => r.id === removeMemberFromGroup.roomId
          );
          if (updateGroup) {
            updateGroup.members = updateGroup.members.filter(
              m => !compareString(m.address, removeMemberFromGroup.info.address)
            );
          }
          return [...prev];
        });
      }
    }
  }, [removeMemberFromGroup]);

  useEffect(() => {
    if (newRoom) {
      appendNewRooms([unifyChatRoom(newRoom)]);
    }
  }, [newRoom]);

  useEffect(() => {
    if (newGroup) {
      if (newGroup.isTribe) {
        appendNewRooms([unifyChatTribe(newGroup)]);
      } else {
        appendNewRooms([unifyChatGroup(newGroup)]);
      }
    }
  }, [newGroup]);

  useEffect(() => {
    if (blockChatMember) {
      const addressBlocks = blockChatMember.address.map(a => a.toLowerCase());
      setRoomMessages(prev => {
        if (prev[blockChatMember.room_id]) {
          prev[blockChatMember.room_id] = prev[blockChatMember.room_id].filter(
            mess => !addressBlocks.includes(mess.from)
            // filter mess.from in blockChatMember.address arra
          );
        }
        return { ...prev };
      });
    }
  }, [blockChatMember]);

  const addRealTimeMessage = useCallback(
    (_m: DMMessage, isFromSocket = true) => {
      if (_m) {
        const timeSend = createLocalTime();
        const rTMessage = formatMessageResponse<DMMessage>(_m);
        const appendMessage: DMMessage = {
          ...rTMessage,
          from: rTMessage.from || '',
          id: rTMessage.id || v4(),
          createdAt: rTMessage.createdAt || timeSend,
          updatedAt: rTMessage.updatedAt || timeSend,
          mediaUrls: rTMessage.mediaUrls || ([] as any),
        };

        // const foundRoom = roomsRef.current.find(r => r.id === rTMessage.roomId);

        setRoomMessages(prev => {
          if (prev[appendMessage.roomId]) {
            const isSendingMessage = prev[appendMessage.roomId].find(
              m =>
                m.isLoading &&
                compareString(m.from, appendMessage.from) &&
                m.content === appendMessage.content
            );
            if (
              !isSendingMessage &&
              !prev[appendMessage.roomId].find(m => m.id === appendMessage.id)
            ) {
              const messageList = [
                ...prev[appendMessage.roomId],
                appendMessage,
              ];
              // compare with owner when have sending message

              if (appendMessage.roomId === activeRoomRef.current?.id) {
                prev[appendMessage.roomId] = messageList;
              } else {
                // just keep maximum 10 message on every availble room
                if (
                  messageList.length < ROOM_CHAT_ON_AVAILABLE_MESSAGE_LENGTH
                ) {
                  prev[appendMessage.roomId] = messageList;
                } else {
                  prev[appendMessage.roomId] = messageList.slice(
                    messageList.length - ROOM_CHAT_ON_AVAILABLE_MESSAGE_LENGTH
                  );
                }
              }
            } else {
              const foundMessageIndex = prev[appendMessage.roomId].findIndex(
                m => m.id === appendMessage.id
              );
              if (foundMessageIndex > -1) {
                const updatedMessage =
                  prev[appendMessage.roomId][foundMessageIndex];
                prev[appendMessage.roomId][foundMessageIndex] = {
                  ...updatedMessage,
                  ...appendMessage,
                  forwardFromId:
                    appendMessage.forwardFromId || updatedMessage.forwardFromId,
                  metadata: appendMessage.metadata || updatedMessage.metadata,
                  from: appendMessage?.from || updatedMessage?.from,
                  contentCn:
                    updatedMessage.contentCn || appendMessage.contentCn,
                  contentJp:
                    updatedMessage.contentJp || appendMessage.contentJp,
                  contentTw:
                    updatedMessage.contentTw || appendMessage.contentTw,
                  contentEn:
                    updatedMessage.contentEn || appendMessage.contentEn,
                };
              }
            }
          } else {
            prev[appendMessage.roomId] = [appendMessage];
          }
          if (
            activeRoomRef.current &&
            activeRoomRef.current.id === appendMessage.roomId
          ) {
            // remove duplicate message
            prev[appendMessage.roomId] = uniqBy(
              prev[appendMessage.roomId],
              'id'
            );
          }

          return { ...prev };
        });

        if (isFromSocket) {
          const yourAddressLowerCase = `${addressL2Ref.current}`?.toLowerCase();
          setRooms(prev => {
            const updateRoom = prev.find(r => r.id === appendMessage.roomId);
            if (updateRoom) {
              if (updateRoom?.lastMessage?.id === appendMessage.id) {
                updateRoom.lastMessage = {
                  ...updateRoom.lastMessage,
                  ...appendMessage,
                  from: appendMessage?.from || updateRoom?.lastMessage?.from,
                };
              } else {
                updateRoom.lastMessage = appendMessage as ChatMessageResponse;
              }

              if (!compareString(addressL2Ref.current, appendMessage.from)) {
                if (updateRoom.type === 'CHAT_ROOM') {
                  updateRoom.unreadCount += 1;
                } else {
                  if (appendMessage.mentions.includes(yourAddressLowerCase)) {
                    updateRoom.unreadCount += 1;
                  }
                }
              }
            }
            return [...prev];
          });
        }
      }
    },
    []
  );

  const deleteRealTimeMessage = useCallback((message: DMMessage) => {
    if (message) {
      setRoomMessages(prev => {
        return {
          ...prev,
          [message.roomId]: (prev[message.roomId] || []).filter(
            item => item.id !== message.id
          ),
        };
      });
    }
  }, []);

  useEffect(() => {
    addRealTimeMessage(newMessage as DMMessage);
  }, [newMessage]);

  useEffect(() => {
    addRealTimeMessage(newGroupMessage as DMMessage);
  }, [newGroupMessage]);

  useEffect(() => {
    deleteRealTimeMessage(deleteMessageGroup as DMMessage);
  }, [deleteMessageGroup]);

  useEffect(() => {
    addRealTimeMessage(updateGroupMessageTip as DMMessage);
  }, [updateGroupMessageTip]);

  const appendMessagesToRoom = useCallback(
    (roomId: string, appendMessages: DMMessage[], shouldReverse = true) => {
      const reverseMessage = shouldReverse
        ? appendMessages.reverse()
        : appendMessages;

      setRoomMessages(prev => {
        const filteredMessages = differenceBy(
          reverseMessage,
          prev[roomId] || [],
          'id'
        );

        return {
          ...prev,
          [roomId as string]: [...filteredMessages, ...(prev[roomId] || [])],
        };
      });
    },
    []
  );

  const addActiveRoomUIMessage = useCallback((_m: DMMessage) => {
    if (_m) {
      const newMessage = formatMessageResponse<DMMessage>(_m);
      setRoomMessages(prev => {
        if (prev[newMessage.roomId]) {
          if (!prev[newMessage.roomId].find(m => m.id === newMessage.id)) {
            prev[newMessage.roomId] = [...prev[newMessage.roomId], newMessage];
          }
        } else {
          prev[newMessage.roomId] = [newMessage];
        }
        return { ...prev };
      });
    }
  }, []);

  const updateActiveRoomUIMessage = useCallback((_m: DMMessage) => {
    if (_m) {
      const newMessage = formatMessageResponse<DMMessage>(_m);
      setRoomMessages(prev => {
        if (prev[newMessage.roomId]) {
          const messageIndex = prev[newMessage.roomId].findIndex(
            m => m.uiid === newMessage.uiid
          );
          if (messageIndex >= 0) {
            prev[newMessage.roomId][messageIndex] = newMessage;
          }
        }
        // remove duplicate message
        prev[newMessage.roomId] = uniqBy(prev[newMessage.roomId], 'id');
        return { ...prev };
      });

      setRooms(prev => {
        const updateRoom = prev.find(r => r.id === newMessage.roomId);
        if (updateRoom) {
          updateRoom.lastMessage = newMessage as ChatMessageResponse;
        }
        return prev;
      });
    }
  }, []);

  useEffect(() => {
    if (id || address) {
      setScreen('CHAT');
    } else {
      setScreen('HOME');
    }
  }, [id, address]);

  const activeRoomMessages = useMemo(() => {
    if (id) {
      return roomMessages[id as string] || [];
    }
    return [];
  }, [id, roomMessages]);

  const fetchActiveRoomMessage = (force = false) => {
    if (activeRoom && pathname === ROUTE_PATH.ALPHA_MOBILE_DM) {
      if (force || !activeRoom.messageLoaded) {
        activeRoom.messageLoaded = true;
        getMessageByTime(activeRoom.type, {
          limit: DEFAULT_LIMIT_GET_MESSAGE,
          roomId: activeRoom.id,
        }).then(({ total, messages: mess }) => {
          const reverseMessage = mess.reverse();

          setRoomMessages(prev => ({
            ...prev,
            [id as string]: reverseMessage,
          }));
        });
      }
    }
  };

  useEffect(() => {
    fetchActiveRoomMessage();
  }, [activeRoom?.id, pathname]);

  const updateRoomLastSeen = useCallback((id: string, time: string) => {
    if (id && time) {
      setRooms(prev => {
        const updateRoom = prev.find(r => r.id === id);
        if (updateRoom) {
          if (updateRoom.lastSeen !== time) {
            if (moment(time).isAfter(updateRoom.lastSeen)) {
              updateRoom.lastSeen = time;
            }
          }

          updateRoom.unreadCount = 0;
        }
        return [...prev];
      });
    }
  }, []);

  const getUnreadMessages = useCallback((type?: ChatType) => {
    if (type) {
      return roomsRef.current
        .filter(r => r.type === type)
        .map(r => r.unreadCount)
        .reduce((partialSum, cv) => partialSum + cv, 0);
    }
    return roomsRef.current
      .map(r => r.unreadCount)
      .reduce((partialSum, cv) => partialSum + cv, 0);
  }, []);

  const getUserProfile = useCallback(
    (addr: string | undefined): IGetPlayerPoolProfile | null => {
      if (addr) {
        return poolProfilePersistor.getMemoryCaches()[`${addr}`.toLowerCase()];
      }
      return null;
    },
    []
  );

  const updateRoomSoundSetting = useCallback((id: string, muted: boolean) => {
    setRooms(prev => {
      const updatedRoom = prev.find(r => r.id === id);
      if (updatedRoom) {
        updatedRoom.mute = muted;
      }
      return [...prev];
    });
  }, []);

  const updateRoomBlockFtSetting = useCallback(
    (id: string, status: boolean) => {
      setRooms(prev => {
        const updatedRoom = prev.find(r => r.id === id);
        if (updatedRoom) {
          updatedRoom.setting.allowFtJoin = status;
        }
        return [...prev];
      });
    },
    []
  );

  const checkRoomPermission = useCallback(async (roomId: string) => {
    // Please consider to use this function
    try {
      const matchedRoom = roomsRef.current.find(item => item.id === roomId);
      if (!!matchedRoom) {
        return true;
      }
      const room = await getRoomById(roomId as string);
      return !!room;
    } catch (e) {
      return false;
    }
  }, []);

  const tryGetRoomByIdAndAddToYourRoom = useCallback((id: string) => {
    getRoomById(id).then(roomDetail => {
      if (roomDetail) {
        appendNewRooms([roomDetail]);
      }
    });
  }, []);

  const initRoomMembersInCall = useCallback(async (id: string) => {
    if (!memberInCallsRef.current[id]) {
      const res = await getJamUserByRoomId(id);
      if (res) {
        setMemberInCalls(prev => {
          prev[id] = res;
          return { ...prev };
        });
      }
    }
  }, []);

  const updateRoomLanguageSettings = useCallback(
    (roomId: string, lang: LanguageSupportedEnum) => {
      setRooms(prev => {
        const foundRoomIndex = prev.findIndex(item => item.id === roomId);
        if (foundRoomIndex >= 0) {
          prev[foundRoomIndex] = {
            ...prev[foundRoomIndex],
            language: lang,
          };
        }
        return [...prev];
      });
    },
    []
  );

  const updateRoomPinned = useCallback((roomId: string, pinned: boolean) => {
    const updateRoom = roomsRef.current.find(r => r.id === roomId);
    if (updateRoom) {
      if (updateRoom.pinned !== pinned) {
        setPinChatRoom(updateRoom.type, roomId, pinned);
      }
    }

    setRooms(prev => {
      const foundRoomIndex = prev.findIndex(item => item.id === roomId);
      if (foundRoomIndex >= 0) {
        prev[foundRoomIndex] = {
          ...prev[foundRoomIndex],
          pinned: pinned,
        };
        chatRoomPersistor.upsertItem(roomId, prev[foundRoomIndex]);
      }

      const sortArr = prev.sort((a, b) =>
        a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1
      );

      return [...sortArr];
      // return [...prev];
    });
  }, []);

  useEffect(() => {
    if (pinUpdateSocket) {
      updateRoomPinned(pinUpdateSocket.roomId, pinUpdateSocket.pinned);
    }
  }, [pinUpdateSocket, updateRoomPinned]);

  const fetchGroupMembers = useCallback(async () => {
    try {
      if (!activeRoom) return;
      const { members } = await getRoomMembersByRoomId(activeRoom?.id);
      setActiveRoomMembers(
        members.filter(
          mem => mem.address.toLowerCase() !== activeRoom?.owner.toLowerCase()
        )
      );
    } catch (error) {}
  }, [activeRoom?.id]);

  const shouldShowGame = useMemo(() => {
    // return activeRoom?.type === 'CHAT_GROUP';

    // TODO: reopen when games ready
    return (
      activeRoom?.type === 'CHAT_GROUP' ||
      (activeRoom?.type === 'CHAT_TRIBE' && activeRoom?.kind === 4)
    );
  }, [activeRoom?.type]);

  useEffect(() => {
    if (activeRoom?.id) {
      fetchGroupMembers();
    }
  }, [activeRoom?.id]);

  useEffect(() => {
    if (activeRoom?.id) {
      if (
        (addMemberToGroup && addMemberToGroup.info) ||
        (removeMemberFromGroup && removeMemberFromGroup.info)
      ) {
        fetchGroupMembers();
      }
    }
  }, [activeRoom?.id, addMemberToGroup, removeMemberFromGroup]);

  const handleOpenMintMessageModal = (messageId: string) => {
    dispatch(
      openModal({
        id: MINT_MESSAGE_MODAL_NAME,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: false,
        render: () => (
          <MintMessageModal
            onClose={handleCloseMintMessageModal}
            messageId={messageId}
          />
        ),
      })
    );
  };

  const handleCloseMintMessageModal = useCallback(() => {
    dispatch(closeModal({ id: MINT_MESSAGE_MODAL_NAME }));
  }, [dispatch]);

  const findLocalMessageById = useCallback(
    (messageId: string) => {
      let message = activeRoomMessages.find(m => m.id === messageId);
      if (message) {
        return message;
      } else {
        message = aroundMessages.find(m => m.id === messageId);
        if (message) {
          return message;
        }
      }
    },
    [activeRoom, activeRoomMessages, aroundMessages]
  );

  const searchMessageById = useCallback(
    async (messageId: string) => {
      try {
        let message = findLocalMessageById(messageId);
        if (message) {
          return message;
        }
        if (activeRoom) {
          return await getChatMessageDetail(activeRoom.type, messageId);
        }
      } catch (e) {
        //
      }
    },
    [findLocalMessageById]
  );

  const updateAIProfiles = useCallback(
    (token: string, profile: IGetPlayerPoolProfile | null) => {
      if (profile) {
        setAIProfiles(prev => {
          if (!prev[token] && profile) {
            return {
              ...prev,
              [token]: {
                ...(prev[token] || {}),
                profile: profile,
              },
            };
          }
          return prev;
        });
      }
    },
    []
  );

  const getRoomChatAvailableOfUserByTwitterId = useCallback(
    async (twitterId: string, noCheckClient = false): Promise<DMRoom[]> => {
      try {
        if (noCheckClient) {
          if (roomChatAvailableOfUsersRef.current[twitterId]?.length) {
            return roomChatAvailableOfUsersRef.current[twitterId];
          }
        }

        const { rooms } = await getAvailableRoomsByTwitterId({
          twitterId,
        });

        setRoomChatAvailableOfUsers(prev => {
          return {
            ...prev,
            [twitterId]: rooms,
          };
        });
        return rooms;
      } catch (e) {
        return [];
      }
    },
    []
  );

  useEffect(() => {
    if (activeRoom && activeRoom?.ownerInfo?.twitterId) {
      getRoomChatAvailableOfUserByTwitterId(activeRoom.ownerInfo?.twitterId);
    }
  }, [activeRoom]);

  const getPotentialRoomByWalletAddress = useCallback(
    async (walletAddress: string): Promise<DMRoom | undefined> => {
      const localChatRooms = rooms.filter(
        item =>
          ['CHAT_GROUP', 'CHAT_TRIBE'].includes(item.type) &&
          compareString(item.ownerInfo?.address, walletAddress)
      );

      const localTribe = localChatRooms.find(item => item.kind === 4);
      if (localTribe) {
        return localTribe;
      }
      const localCircle = localChatRooms.find(
        item => item.type === 'CHAT_GROUP'
      );
      if (localCircle) {
        return localCircle;
      }

      const localPotentialRoom = localChatRooms[0];
      if (localPotentialRoom) {
        return localPotentialRoom;
      }
      const userProfile = await poolProfilePersistor.getItem(walletAddress);
      if (userProfile) {
        const remoteRooms = await getRoomChatAvailableOfUserByTwitterId(
          userProfile.twitterId
        );

        const remoteTribe = remoteRooms.find(
          item => item.type === 'CHAT_TRIBE' && item.kind === 4
        );
        if (remoteTribe) {
          return remoteTribe;
        }

        const remoteCircle = remoteRooms.find(
          item => item.type === 'CHAT_GROUP'
        );
        if (remoteCircle) {
          return remoteCircle;
        }

        const remotePotentialRoom = localChatRooms[0];
        if (remotePotentialRoom) {
          return remotePotentialRoom;
        }
      }
    },
    [getRoomChatAvailableOfUserByTwitterId, rooms]
  );

  const listChatGroup = useApiInfinite(
    getChatGroup,
    {
      key: 'chatGroup',
      limit: DEFAULT_GET_ROOM_LIMIT,
      page: 1,
    },
    {
      revalidateOnFocus: true,
      parallel: true,
    }
  );

  const refreshListChatGroup = () => {
    if (
      listChatGroup?.isRefreshing === false &&
      typeof listChatGroup?.refresh === 'function'
    ) {
      listChatGroup?.refresh();
    }
  };

  const handlePinCommunity = async ({
    ownerToken,
    pinned,
  }: {
    ownerToken: string;
    pinned: boolean;
  }): Promise<boolean> => {
    const result = await pinCommunity({
      ownerToken,
      pinned,
    });
    refreshListChatGroup();
    return result;
  };

  useEffect(() => {
    timeoutIdRef.current = setTimeout(() => {
      refreshListChatGroup();
    }, 60 * 1000); // refresh 1 min from socket
    return () => {
      timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
    };
  }, [newGroupMessage]);

  useEffect(() => {
    if (roomOwnerTwitterId) {
      getRoomChatAvailableOfUserByTwitterId(roomOwnerTwitterId);
    }
  }, [roomOwnerTwitterId]);

  const values = useMemo(() => {
    return {
      screen,
      setScreen,
      roomId,
      setRoomId,
      rooms,
      setRooms,
      roomMessages,
      setRoomMessages,
      activeRoomMessages,
      activeRoom,
      yourPoolProfile,
      addActiveRoomUIMessage,
      updateActiveRoomUIMessage,
      appendMessagesToRoom,
      updateRoomLastSeen,
      getUnreadMessages,
      tab,
      setTab,
      updateRoomSoundSetting,
      updateRoomBlockFtSetting,
      getUserProfile,
      appendNewRooms,
      checkRoomPermission,
      voiceRoom,
      setVoiceRoom,
      joinedVoiceRoom,
      setJoinedVoiceRoom,
      // userOnCallList,
      tryGetRoomByIdAndAddToYourRoom,
      updateRoomLanguageSettings,
      updateRoomPinned,

      activeRoomMembers,
      setActiveRoomMembers,
      fetchGroupMembers,
      isBlockedFromGroupChat,
      dataReply,
      setDataReply,
      dataReplyPreview,
      setDataReplyPreview,
      initRoomMembersInCall,

      aroundMessages,
      setAroundMessages,
      fetchTribes,
      memberInCalls,
      shouldShowGame,

      handleOpenMintMessageModal,
      handleCloseMintMessageModal,

      findLocalMessageById,
      searchMessageById,
      updateAIProfiles,
      AIProfiles,
      showAppSelect,
      setShowAppSelect,
      openSticker,
      setOpenSticker,
      openBottomBar,

      roomChatAvailableOfUsers,

      getRoomChatAvailableOfUserByTwitterId,

      getPotentialRoomByWalletAddress,

      roomOwnerTwitterId,
      setRoomOwnerTwitterId,

      handlePinCommunity,
      listChatGroup,
      showBackBtn,
      setShowBackBtn,
      isTyping,
      setIsTyping,
      isRoomActiveIsCommunity,
      isRoomActiveIsOwner,

      isForwardMessageTargets,
      onOpenForwardMessageTargets,
      onCloseForwardMessageTargets,

      forwardMessage,
      setForwardMessage,
    };
  }, [
    screen,
    setScreen,
    roomId,
    setRoomId,
    rooms,
    setRooms,
    roomMessages,
    setRoomMessages,
    activeRoomMessages,
    activeRoom,
    yourPoolProfile,
    addActiveRoomUIMessage,
    updateActiveRoomUIMessage,
    appendMessagesToRoom,
    updateRoomLastSeen,
    getUnreadMessages,
    tab,
    setTab,
    updateRoomSoundSetting,
    updateRoomBlockFtSetting,
    getUserProfile,
    appendNewRooms,
    checkRoomPermission,
    voiceRoom,
    setVoiceRoom,
    joinedVoiceRoom,
    setJoinedVoiceRoom,
    // userOnCallList,
    tryGetRoomByIdAndAddToYourRoom,
    updateRoomLanguageSettings,
    updateRoomPinned,
    initRoomMembersInCall,

    activeRoomMembers,
    setActiveRoomMembers,

    fetchGroupMembers,
    isBlockedFromGroupChat,
    dataReply,
    setDataReply,
    dataReplyPreview,
    setDataReplyPreview,

    aroundMessages,
    setAroundMessages,

    memberInCalls,
    fetchTribes,
    shouldShowGame,

    handleOpenMintMessageModal,
    handleCloseMintMessageModal,
    findLocalMessageById,
    searchMessageById,
    updateAIProfiles,
    AIProfiles,
    showAppSelect,
    setShowAppSelect,
    openSticker,
    setOpenSticker,
    openBottomBar,

    roomChatAvailableOfUsers,

    getRoomChatAvailableOfUserByTwitterId,

    getPotentialRoomByWalletAddress,
    roomOwnerTwitterId,
    setRoomOwnerTwitterId,
    handlePinCommunity,
    listChatGroup,
    showBackBtn,
    setShowBackBtn,
    isTyping,
    setIsTyping,
    isRoomActiveIsCommunity,
    isRoomActiveIsOwner,

    isForwardMessageTargets,
    onOpenForwardMessageTargets,
    onCloseForwardMessageTargets,

    forwardMessage,
    setForwardMessage,
  ]);

  return (
    <dmContext.Provider value={values}>
      {children}
      <VoiceRoomDrawer />
    </dmContext.Provider>
  );
}

export function useDM() {
  return useContext(dmContext);
}
