import { Input } from '@/components/InputDark';
import { WalletContext } from '@/contexts/wallet-context';
import useWindowSize from '@/hooks/useWindowSize';
import { GetTcPayType, IGetTcResponse } from '@/interfaces/topup';
import { requestGetTc } from '@/services/topup';
import { validateWalletAddress } from '@/utils';
import { formatEthPrice } from '@/utils/format';
import px2rem from '@/utils/px2rem';
import { Flex, Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CheckHistory, FormContainer } from './SubmitForm.styled';

interface IFormValue {
  amount: string;
  toAddress: string;
}

interface SubmitFormProps {
  isProcessing: boolean;
  onSubmitGenerate: (
    tcAddress: string,
    tcAmount: number,
    payType: GetTcPayType
  ) => void;
  tcAmount?: number;
  tcAddress?: string;
  paymentInfo?: IGetTcResponse;
  isJackpotDeposit?: boolean;
}

const SubmitForm = (props: SubmitFormProps) => {
  const {
    isProcessing,
    onSubmitGenerate,
    tcAddress,
    tcAmount,
    isJackpotDeposit,
  } = props;
  const paytypeRef = useRef<GetTcPayType>('ethereum');
  const [ethDexPrice, setEthDexPrice] = useState<number | undefined>(undefined);
  const currentEthDexPrice = useRef<number | undefined>(undefined);
  const { mobileScreen } = useWindowSize();
  const { addressL2 } = useContext(WalletContext);
  const { account } = useWeb3React();
  const router = useRouter();
  const params = router.query;

  const _toAddress =
    tcAddress ||
    (params?.address && params.address !== ''
      ? (params.address as string)
      : '') ||
    addressL2 ||
    account ||
    '';

  const toAddress = isJackpotDeposit ? tcAddress || '' : _toAddress;

  useEffect(() => {
    handleGetEthDexPrice();
  }, []);

  const handleGetEthDexPrice = async (amount: number = 10) => {
    try {
      // use to get exchange price of eth on dex
      if (currentEthDexPrice.current !== amount) {
        setEthDexPrice(undefined);
        currentEthDexPrice.current = amount;
        const testAddress = '0x70b5AB86461f8A38db04C0Bc307162CC98ec122c';
        const data = await requestGetTc({
          tcAddress: testAddress,
          tcAmount: amount,
          payType: 'ethereum',
          reCaptcha: '',
        });
        if (data && data.paymentAmount) {
          setEthDexPrice(Number(formatEthPrice(data.paymentAmount)));
        }
      }
    } catch (error) {}
  };

  const debounceeGetEthDexPrice = React.useCallback(
    debounce(handleGetEthDexPrice, 600),
    []
  );

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};
    const minAmount = isJackpotDeposit ? 1.5 : 0.01;

    if (!values.toAddress) {
      errors.toAddress = 'Receiving wallet address is required.';
    } else if (!validateWalletAddress(values.toAddress)) {
      errors.toAddress = 'Invalid receiving wallet address.';
    }

    if (!values.amount) {
      errors.amount = 'Amount is required.';
    } else if (Number(values.amount) < minAmount) {
      errors.amount = `The minimum amount is ${minAmount} TC.`;
    } else {
      debounceeGetEthDexPrice(Number(values.amount));
    }

    return errors;
  };

  const handleSubmit = async (payload: IFormValue): Promise<void> => {
    onSubmitGenerate(
      payload.toAddress,
      Number(payload.amount),
      paytypeRef.current
    );
  };

  const renderCustomAmount = () => {
    return (
      <Formik
        key="buy"
        initialValues={{
          toAddress: toAddress,
          amount:
            tcAmount && Number(tcAmount) > 0
              ? `${tcAmount}`
              : params.amount && Number(params.amount) > 0
                ? `${params.amount}`
                : '10',
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Input
              title={`AMOUNT TC`}
              id="amount"
              type="number"
              name="amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.amount}
              className="input"
              placeholder={`Amount`}
              errorMsg={
                errors.amount && touched.amount ? errors.amount : undefined
              }
            />
            {!isJackpotDeposit && (
              <Input
                title="RECEIVING WALLET ADDRESS"
                id="toAddress"
                type="text"
                name="toAddress"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.toAddress}
                className="input"
                placeholder={`Paste your wallet address here (0x1234...2345).`}
                errorMsg={
                  errors.toAddress && touched.toAddress
                    ? errors.toAddress
                    : undefined
                }
              />
            )}

            <Flex flexDirection="column" mt="8" gap={6}>
              <button
                disabled={
                  isProcessing ||
                  !!(errors.toAddress && touched.toAddress) ||
                  !toAddress
                }
                type="submit"
                className="confirm-btn"
                onClick={() => (paytypeRef.current = 'ethereum-mm')}
              >
                {isProcessing && paytypeRef.current === 'ethereum-mm' ? (
                  'Processing...'
                ) : (
                  <>
                    Pay <div style={{ width: 8 }}></div>
                    {ethDexPrice && values.amount && !errors.amount && (
                      <>{ethDexPrice} ETH</>
                    )}
                  </>
                )}
              </button>
              <Flex
                flexDirection="row"
                mt="8"
                justifyContent="space-between"
                gap={6}
              >
                <button
                  disabled={!!(errors.toAddress && touched.toAddress)}
                  type="submit"
                  className="viewQr"
                  onClick={() => (paytypeRef.current = 'ethereum')}
                >
                  View payment address (ETH)
                </button>

                <button
                  disabled={!!(errors.toAddress && touched.toAddress)}
                  type="submit"
                  className="viewQr"
                  onClick={() => (paytypeRef.current = 'bitcoin')}
                >
                  View payment address (BTC)
                </button>
              </Flex>

              <Text color="#cecece" fontSize={px2rem(16)} fontWeight={600}>
                Estimated Waiting Time
              </Text>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                w="100%"
              >
                <Text color="#cecece" fontSize={px2rem(14)} fontWeight={600}>
                  ETH
                </Text>
                <Text color="#cecece" fontSize={px2rem(14)} fontWeight={600}>
                  ~ 20s
                </Text>
              </Flex>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                w="100%"
              >
                <Text color="#cecece" fontSize={px2rem(14)} fontWeight={600}>
                  BTC
                </Text>
                <Text color="#cecece" fontSize={px2rem(14)} fontWeight={600}>
                  ~ 10 minutes
                </Text>
              </Flex>

              <Text
                color="#cecece"
                fontSize={px2rem(16)}
                maxWidth={px2rem(mobileScreen ? 400 : 520)}
                fontWeight={600}
                mt={4}
              >
                You are purchasing BVM through New Bitcoin DEX. Please keep in
                mind that the amount of BVM you receive may differ slightly from
                the estimated amount here due to rate volatility.
              </Text>
            </Flex>
          </FormContainer>
        )}
      </Formik>
    );
  };

  return (
    <div
      style={{
        visibility: props.paymentInfo ? 'hidden' : 'visible',
        height: props.paymentInfo ? 0 : 'auto',
      }}
    >
      {renderCustomAmount()}
      {toAddress && (
        <CheckHistory>
          <a
            target="_blank"
            href={`/topup/history?address=${toAddress}`}
            className="checkHistory checkHistoryModal"
          >
            Check your TC transaction history here.
          </a>
        </CheckHistory>
      )}
    </div>
  );
};

export default SubmitForm;
