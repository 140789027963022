import {API_DGAMES, PERP_API_URL} from '@/configs';
import { TWITTER_PROFILE } from '@/constants/storage-key';
import {
  IReferralEarning,
  IReferralHistoryRecord,
} from '@/interfaces/api/referral';
import { apiClient } from '@/services';
import { getPlayerPoolProfile2 } from '@/services/player-share';
import accountStorage from '@/utils/account.storage';
import { uniqueId } from 'lodash';
import qs from 'qs';
import { v4 } from 'uuid';
import {camelCaseKeys} from "@/utils/commons";

const API_PATH = 'v1/referral';
//referral/get-referral-code?twitterId=723175371416313856

// export const getReferralCode = async () => {
//   try {
//     const cacheProfile = accountStorage.getTwitterProfile();
//     if (cacheProfile.twitterUsername) {
//       return `@${cacheProfile.twitterUsername}`;
//     }
//   } catch (e) {
//     //
//   }
//   try {
//     const address = accountStorage.getAddress();
//     const profile = await getPlayerPoolProfile2(address);
//     if (profile.twitterUsername) {
//       return `@${profile.twitterUsername}`;
//     }
//   } catch (e) {
//     //
//   }
//   return ((await apiClient.get(`${API_DGAMES}/${API_PATH}/code`)) as any).code;
// };
export const getReferralCode = async () => {
  return ((await apiClient.get(`${API_DGAMES}/${API_PATH}/code`)) as any).code;
};

export const getReferralCodeByTwId = async (payload: {
  twitter_id: string;
}) => {
  return (
    (await apiClient.get(
      `${API_DGAMES}/${API_PATH}/get-referral-code?twitterId=${payload.twitter_id}`
    )) as any
  ).code;
};

export const useReferralCode = async (payload: { code: string }) => {
  return (await apiClient.post(`${API_DGAMES}/${API_PATH}`, {
    ...payload,
  })) as any;
};

export const getAddressReferrer = async () => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/referrer`);
  return (res as any).referrer;
};

export const getAddressReferents = async (): Promise<{ address: string }[]> => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/referents`);
  return (res as any).referents;
};

export const getAddressReferralEarning =
  async (): Promise<IReferralEarning> => {
    const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/earning`);
    return res as any;
  };

export const claimReferralEarning = async () => {
  return (
    (await apiClient.post(`${API_DGAMES}/${API_PATH}/claim-earning`, {})) as any
  ).result;
};

export const getReferralHistory = async ({
  page = 1,
  limit = 100,
}: {
  page?: number;
  limit?: number;
}): Promise<{
  history: IReferralHistoryRecord[];
  total: number;
}> => {
  const query = qs.stringify({
    page,
    limit,
  });
  const res = await apiClient.get(
    `${API_DGAMES}/${API_PATH}/claim-history?${query}`
  );
  return res as any;
  // return {
  //   history: [
  //     ...Array.from(Array(limit)).map(() => ({
  //       id: v4(),
  //       token: v4(),
  //       amount: Math.random().toFixed(10),
  //       txHash: v4(),
  //       createdAt: new Date().toISOString(),
  //       updatedAt: new Date().toISOString(),
  //     })),
  //   ],
  //   total: 500,
  // };
};

export const claimGiftCode = async (payload: {
  address: string;
  code: string;
}): Promise<any> => {
  try {
    const { address,code } = payload;

    const res = await apiClient.post(
      `${PERP_API_URL}/api/user/gift-codes/claim?network=nos&address=${address}&code=${code}`,
      {}
    );
    return res
  } catch (err: unknown) {
    console.error('claimGiftCode error', err);
    throw err;
  }
};
